import { sanitizeUrl } from 'utils/sanitizeUrl';
import { toastMessages } from 'components/toastMessage/signals.ts';
import { cn } from 'utils/cn.tsx';
import { Icon } from 'components/atoms/Icon.tsx';
import { localize } from 'i18n/localize.ts';

const $t = localize();

export function ToastMessage() {
	return (
		<div
			class="fixed top-[142px] md:top-[76px] z-toast pointer-events-none [&>*]:pointer-events-auto"
			data-test="toast-message-list"
		>
			{toastMessages.value.map(({ type, messageParts, id }) => (
				<div
					key={id}
					class={cn(
						'bg-white border border-gray-200 rounded-2xl md:rounded-full  shadow-md py-3 px-4 md:py-[10px] md:px-6 mb-4 md:mb-6 mx-4 md:mx-8 flex items-center gap-3 md:gap-12 w-fit max-w-full text-base animate-revealFromLeft',
						{
							'bg-green-light': type === 'success',
							'bg-red-light': type === 'error',
						},
					)}
				>
					<div class="flex-auto flex items-center">
						{type === 'info' && (
							<Icon className="mr-3 text-dark-blue-25 flex-shrink-0" type="info" size={24} fill />
						)}
						{type === 'success' && (
							<Icon className="mr-3 text-green flex-shrink-0" type="check_circle" size={24} fill />
						)}
						<span class="inline-flex gap-1 whitespace-pre-line">
							{messageParts.map(({ text, href }) =>
								href ? (
									<a class="link" href={sanitizeUrl(href)}>
										{text}
									</a>
								) : (
									text
								),
							)}
						</span>
					</div>
					<button
						class="flex-initial flex items-center flex-shrink-0"
						onClick={() => {
							toastMessages.removeMessage(id);
						}}
						title={$t.modal.close}
					>
						<Icon className="text-dark-blue-50" type="close" size={24} />
					</button>
				</div>
			))}
		</div>
	);
}
